import React from 'react'
const Header = () => {
    return (
        <section className="relative px-0 sm:px-4 md:px-8 py-32 -mt-20 overflow-hidden">
            <div className="z-0 bg-gray-400 absolute transform -translate-y-24 md:-translate-y-48 lg:-translate-y-56 w-full h-full left-0 top-0 right-0 bottom-0"></div>
            <div className="mx-auto max-w-6xl px-4 sm:px-6 w-full flex flex-row flex-wrap relative z-10">
                <div className="w-full md:w-1/2 p-2 sm:p-4 lg:p-8 xl:p-16">
                    <h1 className="text-gray-800 font-bold text-4xl lg:text-5xl leading-none">
                        SAP reference books
                    </h1>
                    <hr className="border-0 w-16 h-1 bg-orange-500 my-4"/>
                    <p className="text-base mb-4 text-gray-700">
                        Espresso Tutorials offers you learning media that focuses on the essentials for your journey from taking initial steps as a beginner to being an SAP professional.
                    </p>
                    <p className="text-base text-gray-700">
                        With our SAP learning platform <a className="text-blue-500 hover:text-orange-500 duration-200 ease-in-out transition-colors underline" href="https://et.training" target="_blank" rel="noopener" title="SAP-Lernplattform et.training aufrufen">et.training</a> you get access to all our e-books and videos. A flat rate according to the motto: „Learn SAP anytime, anywhere, and on any device“.
                    </p>
                </div>
                <div className="hidden md:block w-1/2">
                    <div className="bg-blue-200 rounded-lg px-4 pt-8 w-full">
                        <img decoding="async" className="-ml-12"
                             src="https://www.espresso-tutorials.de/wp-content/uploads/2020/10/cathy-1.png"
                             alt="Espresso Tutorials GmbH" fetchpriority="high" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header
